.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  @include media-breakpoint-up(sm) {
    gap: 15px;
  }
  .thumbnail {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      // padding-top: calc(9 / 16 * 100%);
      // padding-top: calc(3 / 4 * 100%);
      padding-top: calc(2 / 3 * 100%);
      // padding-top: 61.8%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  a.thumbnail {
    display: block;
    img {
      transition: $transition-base;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .title {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-weight: bold;
    font-size: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    a {
      color: $body-color;
      transition: $transition-base;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
  }
  .data {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 12px;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      font-size: 13px;
    }
  }
  .text {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    color: $secondary;
    font-size: 11px;
    @include media-breakpoint-up(sm) {
      font-size: 13px;
    }
  }
}
