.widelayout {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
}
.brandContainer {
  padding: 0 6vw;
}
.brandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  max-width: calc(800px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
}
.magazineCont {
  max-width: 1200px;
  margin: 0 auto;
  .sideCont {
    margin-top: 60px;
    @include media-breakpoint-up(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .mainCont {
      width: 800px;
    }
    .sideCont {
      width: 320px;
    }
  }
}
.bg-gray {
  background-color: $gray-100;
}
.bg-beige {
  background-color: $bg-beige;
}
.gallery-1-2 {
  display: grid;
  grid-gap: 6px;
  grid-template:
    "main main"
    "sub1 sub2"
    / 50% 50%;
  @include media-breakpoint-up(md) {
    grid-template:
      "main sub1"
      "main sub2"
      / 66% 34%;
  }
  div {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .main {
    grid-area: main;
  }
  .sub1 {
    grid-area: sub1;
  }
  .sub2 {
    grid-area: sub2;
  }
}
