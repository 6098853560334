.greeting {
  display: grid;
  grid-template:
    "cont cont cont"
    ".... ... ...." 15px
    "thumbnail  ... nameBox"
    / 33% 20px 1fr;
  @include media-breakpoint-up(sm) {
    grid-template:
      "thumbnail ... cont" auto
      ".... ... ..." 15px
      ".... ... nameBox"
      / 30% 45px 1fr;
  }
  .thumbnail {
    grid-area: thumbnail;
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(360 / 300 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .cont {
    grid-area: cont;
    > .title {
      @include fontSize(24);
      line-height: 1.5;
      margin-bottom: 1.5em;
      @include media-breakpoint-up(sm) {
        @include fontSizeSM(24);
      }
      @include media-breakpoint-up(lg) {
        @include fontSizeLG(30);
      }
    }
    > .text {
      line-height: 2;
      margin-bottom: 2em;
      br {
        display: none;
        @include media-breakpoint-up(sm) {
          display: inline;
        }
      }
    }
  }
  .nameBox {
    grid-area: nameBox;
  }
}
