.moreLink {
  display: flex;
  color: $body-color;
  text-decoration: none;
  gap: 5px;
  position: relative;
  &::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f105";
    font-weight: normal;
    transition: $transition-base;
    font-weight: 400;
  }
  &:hover {
    &::before {
      transform: translateX(2px);
    }
  }
}
