.guidelineTable {
  width: 100%;
  line-height: 1.5;
  letter-spacing: 0.1em;
  font-size: 14px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  > dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 0 8px;
    margin: 0;

    @include media-breakpoint-up(sm) {
      gap: 0 24px;
    }

    > dt {
      width: 100px;
      border-top: 1px solid $body-color;
      padding: 20px 0;
      margin: 0;
      font-weight: 400;

      @include media-breakpoint-up(sm) {
        width: 200px;
        padding: 24px 0;
      }
    }

    > dd {
      border-top: 1px solid $body-color;
      padding: 20px 0;
      margin: 0;
      flex: 1;
      font-weight: 400;

      @include media-breakpoint-up(sm) {
        padding: 24px 0;
      }
    }

    p,
    ul,
    ol {
      margin: 0;
    }
  }

  &.responsive {
    @include media-breakpoint-down(sm) {
      //スマホのみ縦並びにする
      > dl {
        gap: 0;

        > dt {
          width: 100%;
          padding-bottom: 8px;
        }

        > dd {
          width: 100%;
          border-top: none;
          padding-top: 0;
        }
      }
    }
  }
}
