.topTopics {
  position: relative;
  .bg {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(250 / 390 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
      @include media-breakpoint-up(sm) {
        padding-top: calc(420 / 820 * 100%);
      }
      @include media-breakpoint-up(lg) {
        padding-top: calc(450 / 1366 * 100%);
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: relative;
    z-index: 2;
    padding: 0 6vw;
    margin-top: -75px;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .inner {
      background-color: $white;
      padding: 45px 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      @include media-breakpoint-up(lg) {
        min-width: 450px;
      }
      .midashi1 {
        margin: 0;
      }
      .title {
        font-size: 27px;
        font-weight: bold;
        margin: 0;
        letter-spacing: 0.15em;
        @include media-breakpoint-up(lg) {
          font-size: 45px;
        }
      }
      .price {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}
