$bg-beige: #faf7f4;
$border-beige: #ede5dc;
$headerHeight: 80px;
$headerHeightSM: 100px;
@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "pwid";
  letter-spacing: 0.08em;
}
img {
  max-width: 100%;
  height: auto;
}
@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}
@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}
@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1366 * 100vw);
}
@import "components/header";
@import "components/toggleMenu";
@import "components/hero";
@import "components/gnav-sm";
@import "components/topTopics";
@import "components/greeting";
@import "components/nameBox";
@import "components/moreLink";
@import "components/ctaArea";
@import "components/layout";
@import "components/summary";
@import "components/galleryList";
@import "components/outline";
@import "components/digest";
@import "components/digestCover";
@import "components/priceList";
@import "components/pagination";
@import "components/article";
@import "components/specialThumbnail";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/formTable";
@import "components/brandTable";
@import "components/flow";
@import "components/faq";
@import "components/newsList";
@import "components/newsBar";
@import "components/newsArchive";
@import "components/catchCopyBar";
@import "components/catchCopyBorder";
@import "components/anchorLinks";
@import "components/outlineCard";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/footer";
@import "components/vif";
@import "components/guidelineTable";
