.ctaArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: stretch;
  }
  .cta {
    border: 5px solid $border-color;
    padding: 30px;
    width: 100%;
    .title {
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      text-align: center;
      @include media-breakpoint-up(sm) {
        // height: 100%;
        justify-content: center;
      }
      .tel {
        font-size: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        .icon {
        }
        .number {
          font-weight: bold;
        }
      }
      .info {
        font-size: 15px;
      }
    }
  }
}
