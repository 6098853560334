.secondHeader {
  position: relative;
  padding: 45px 6vw 0;
  .cont {
    position: relative;
    background-color: $bg-beige;
    padding: 45px 30px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    > .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      .title-ja {
        font-weight: bold;
        font-size: 30px;
        margin: 0;
        line-height: 1.2;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
        }
      }
    }
    .footer {
    }
  }
}
