.priceList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: grid;
    margin-bottom: 15px;
    grid-template:
      "title ... line ... price"
      / auto 10px 1fr 10px auto;
    .title {
      grid-area: title;
    }
    .price {
      grid-area: price;
    }
    &::after {
      grid-area: line;
      content: "";
      width: 100%;
      height: 50%;
      border-bottom: 1px dashed $body-color;
    }
  }
}
