.hero {
  position: relative;
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 6vw;
  }
  @include media-breakpoint-up(lg) {
    padding: 60px 6vw 90px;
  }
  &::before {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 85vw;
    height: 90%;
    content: "";
    background-color: $bg-beige;
    @include media-breakpoint-up(sm) {
      width: 90vw;
      height: 60%;
    }
    @include media-breakpoint-up(lg) {
      height: 55%;
    }
  }
  .img {
    position: relative;
    z-index: 2;
    width: 90vw;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      width: calc(450 / 820 * 100vw);
    }
    @include media-breakpoint-up(lg) {
      width: calc(667 / 1366 * 100vw);
    }
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(3 / 4 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    padding: 45px 6vw;
    @include media-breakpoint-up(sm) {
      flex: 1;
      gap: 30px;
    }
    .title {
      @include fontSize(12);
      font-weight: bold;
      margin: 0;
      line-height: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      &::after {
        content: "";
        width: 2em;
        height: 1px;
        background-color: $body-color;
      }
      @include media-breakpoint-up(sm) {
        @include fontSizeSM(12);
      }
      @include media-breakpoint-up(lg) {
        @include fontSizeLG(13);
      }
    }
    .catchCopy {
      @include fontSize(21);
      font-weight: bold;
      margin: 0;
      line-height: 2;
      letter-spacing: 0.15em;
      @include media-breakpoint-up(sm) {
        @include fontSizeSM(21);
      }
      @include media-breakpoint-up(lg) {
        @include fontSizeLG(21);
      }
    }
  }
}
