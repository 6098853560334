.gnav-sm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 30px 3vw;
  @include media-breakpoint-up(sm) {
    gap: 20px;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 0 10px;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }
    li {
      flex-basis: calc((100% - 10px) / 2);
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      a {
        width: 100%;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        padding: 12px 0;
        font-size: 12px;
        font-weight: bold;
        border-bottom: 2px solid $border-beige;
        white-space: nowrap;
        &::after {
          font-family: "Font Awesome 5 Pro";
          content: "\f105";
          font-weight: 400;
        }
      }
    }
  }
  .sns {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 15px;
    li {
      a {
        font-size: 24px;
        text-decoration: none;
      }
    }
  }
}
