#header {
  width: 100%;
  height: $headerHeight;
  padding: 0 6vw;
  display: grid;
  grid-template:
    "logo ..." #{$headerHeight}
    / auto 1fr;
  @include media-breakpoint-up(sm) {
    height: auto;
    grid-template:
      "... logo ..." #{$headerHeightSM}
      "... gnav ..."
      / 1fr auto 1fr;
  }
  .logo {
    grid-area: logo;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding-top: 5px;
    @include media-breakpoint-up(sm) {
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding-top: 10px;
    }
    img {
      height: 24px;
      width: auto;
      @include media-breakpoint-up(sm) {
        height: 30px;
      }
    }
    .address {
      font-size: 11px;
      margin: 0;
    }
  }
  .gnav {
    grid-area: gnav;
    display: none;
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      .menu {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        li {
          a {
            font-size: 13px;
            color: $body-color;
            text-decoration: none;
            transition: $transition-base;
            display: inline-block;
            padding: 3px 0;
            position: relative;
            &::after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 1%;
              height: 1px;
              background-color: $body-color;
              content: "";
              opacity: 0;
              transition: $transition-base;
            }
            &:hover {
              &::after {
                width: 100%;
                opacity: 1;
              }
            }
          }
        }
      }
      .sns {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        li {
          a {
            font-size: 24px;
            color: $body-color;
            text-decoration: none;
            transition: $transition-base;
          }
        }
      }
    }
  }
}
