.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid $border-color;
    a {
      display: flex;
      gap: 15px;
      align-items: center;
      text-decoration: none;
      color: $body-color;
      padding: 20px 0;
      flex-wrap: wrap;
      transition: $transition-base;
      @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;
        align-items: flex-start;
        // padding: 30px 0;
      }
      .date {
        color: $body-color;
      }
      .cat {
        font-size: 12px;
        border: 1px solid $body-color;
        // border-radius: 5px;
        padding: 5px 10px;
        line-height: 1;
        white-space: nowrap;
      }
      .title {
      }
      &:hover {
        background-color: $border-color;
      }
    }
  }
}
