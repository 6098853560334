.nameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  .title {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    line-height: 1;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
  }
  .name {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    line-height: 1;
    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
  }
}
