.galleryList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  gap: 5px;
  li {
    flex-basis: calc((100% - 5px * 3) / 4);
    a {
      display: block;
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(1 / 1 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    p {
      margin: 0;
    }
  }
}
