.midashi1 {
  padding-bottom: 10px;
  position: relative;
  margin: 0;
  font-size: 18px;
  display: inline-block;
  @include media-breakpoint-up(sm) {
    font-size: 21px;
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $body-color;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
