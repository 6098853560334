#footer {
  padding: 30px 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  background-color: $bg-beige;
  @include media-breakpoint-up(sm) {
    padding: 60px 6vw;
  }
  .logo {
    margin: 0;
  }
  .address {
    margin: 0;
    font-size: 12px;
  }
  .tel {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 0;
    a {
      color: $body-color;
      text-decoration: none;
    }
  }
  .sns {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 24px;
    li {
      a {
        color: $body-color;
        text-decoration: none;
      }
    }
  }
  .copyright {
    margin: 0;
    font-size: 10px;
  }
}
