.sectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
  > .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    .title-en {
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      line-height: 1;
      text-transform: uppercase;
    }
    .title-ja {
      padding-bottom: 15px;
      position: relative;
      margin: 0;
      font-size: 30px;
      display: inline-block;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: $body-color;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .footer {
  }
  &.center {
    text-align: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      flex-direction: column;
    }
    > .header {
      align-items: center;
      .title-ja {
        &::after {
          display: block;
        }
      }
    }
  }
}
